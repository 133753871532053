/**
 * Параметры URL.
 */
export const UrlParams = {
  HEIGHT: 'z', // высота камеры
  YAW: 'yaw', // поворот камеры
  PITCH: 'pitch', // наклон камеры
  HASH: 'hash', // хэш объекта
} as const;

export type UrlParams = (typeof UrlParams)[keyof typeof UrlParams];
