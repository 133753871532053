import { customException } from './capture-exception.util';

/**
 * Утилитный класс для преобразования форматов координат.
 */
export class CoordinateConverterUtil {
  /**
   * Преобразует координаты из формата градусов, минут и секунд (ГМС) в десятичные градусы.
   *
   * @param {string} coordinates - Координаты в формате ГМС (например, "40°44′55"").
   * @returns {number} Координаты в десятичных градусах.
   * @throws Выводит сообщение об ошибке в консоль, если формат координат неверен.
   * @example
   * // Вернёт 40.74861111111111
   * CoordinateConverterUtils.convertDegreesMinutesAndSecondToDecimalDegrees("40°44′55"");
   */
  static convertDegreesMinutesAndSecondToDecimalDegrees(coordinates: string): number {
    let decimalDegrees = 0;
    // Нахождение индексов символов для градусов, минут и секунд
    const indexDegreeSymbol = coordinates.indexOf('°'); // Индекс символа градусов
    const indexMinuteSymbol = coordinates.indexOf('′'); // Индекс символа минут
    const indexSecondSymbol = coordinates.indexOf('"'); // Индекс символа секунд

    // Проверка наличия всех необходимых символов в строке координат
    if (indexDegreeSymbol !== -1 && indexMinuteSymbol !== -1 && indexSecondSymbol !== -1) {
      const degrees: number = parseInt(coordinates.substring(0, indexDegreeSymbol));
      const minutes: number = parseInt(coordinates.substring(indexDegreeSymbol + 1, indexMinuteSymbol));
      const seconds: number = parseInt(coordinates.substring(indexMinuteSymbol + 1, indexSecondSymbol));
      // Преобразование в десятичные градусы
      decimalDegrees = degrees + minutes / 60 + seconds / 3600;
    } else {
      customException({ msg: 'Wrong coordinate format' });
    }
    return decimalDegrees;
  }
}
